<template>
  <div id="dialogues">
    <div v-if="showPreview" style="
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
      ">
      <div @click="showPreview = false" style="
          color: white;
          font-size: 30px;
          padding: 30px 30px 15vh;
          text-align: right;
          cursor: pointer;
        ">
        X
      </div>
      <img style="max-height: 70vh; max-width: 70vw" :src="previewImageUrl" alt srcset />
    </div>
    <!-- <div class="content-header">
      <div class="leftInfo">
        <span class="headerName">{{ activePageInfo.headerName }}</span>
        <span>
          <el-select
            v-model="activeBot"
            placeholder="请选择机器人"
            :disabled="getDataIng"
            @change="selectBot"
          >
            <el-option
              v-for="item in botList"
              :key="item.apiKey"
              :label="item.name"
              :value="item.apiKey"
            ></el-option>
          </el-select>
        </span>
      </div>
    </div> -->
    <page-top-header>
      <div slot="top-header-left" class="top-header-left">
        <i class="iconfont guoran-tongyichicun-duihuajilu"></i>
        <span class="page-name">{{ activePageInfo.headerName }}</span>
      </div>
      <div slot="top-header-right" class="top-header-right">
        <el-button @click="showAdvSearch" size="small" round icon="iconfont guoran-gaojishaixuan">{{
          $t('dialogues.screen') }}</el-button>
        <el-select v-model="activeBot" :placeholder="$t('dialogues.selectBot')" size="small" :disabled="getDataIng"
          @change="selectBot">
          <el-option v-for="item in botList" :key="item.apiKey" :label="item.name" :value="item.apiKey"></el-option>
        </el-select>
      </div>
    </page-top-header>
    <div v-if="JSON.stringify(advSearchData) != '{}'" class="chat-adv-show">
      <div class="adv-show-list">
        <div class="tag-b" v-if="advSearchData.lastMsgTime != null">
          <el-tag key="lastMsgTime" size="mini" @close="closeParam('lastMsgTime')" closable>
            {{ $t('dialogues.consultationTime') }}：{{
              formatDateTime(
                advSearchData.lastMsgTime[0]
              )
            }} 至 {{
              formatDateTime(
                advSearchData.lastMsgTime[1]
              )
            }}
          </el-tag>
        </div>
      </div>
      <span class="adv-show-close">
        <i @click="deleteAllAdv" class="iconfont guoran-a-16_01"></i>
      </span>
    </div>
    <div v-show="userList.length != 0 && !isEmpty" :style="{ height: 'calc(100vh - ' + (105 + top) + 'px)' }"
      class="dialogues-content">
      <div class="userList">
        <div class="headerName">{{ $t('dialogues.userList') }}</div>
        <div class="headerName" style="padding: 10px">
          <el-input class="search-user" v-model="searchUserName" :placeholder="$t('dialogues.userPlaceholder')"
            v-on:input="changeInput()" @keyup.enter.native="searchUser()">
            <el-button @click="searchUser()" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="nodata" v-if="isNoData">{{ $t('common.empty') }}</div>
        <div class="userListContent" v-if="!isNoData">
          <!-- 用户信息 -->
          <div class="userItem" v-for="(item, index) in userList" :key="index"
            :class="{ current: activeClass === index }">
            <div class="userItemContent" @click="
              clickUserli(
                index,
                item.destId,
                item.sourceId,
                true,
                null,
                true,
                item.channel,
                item.ext == null ? null : item.ext.agentId
              )
              ">
              <div class="userName">
                <!-- <span class="name">{{
                  item.source == "MINI_PROGRAM_WEB_SOCKET"
                    ? "小程序用户"
                    : item.sourceName
                }}</span> -->
                <span class="name" v-if="item.source == 'MINI_PROGRAM_WEB_SOCKET'">{{ $t('dialogues.miniProgramUsers')
                  }}</span>
                <span class="name" v-else-if="item.source == 'WEBSITE_SOCKET'">
                  <span v-if="item.wechatBot && item.wechatBot.channel === 0 && item.wechatBot.bindType == 0">
                    <open-data :type="'userName'" :openid="item.sourceName"></open-data>
                  </span>

                  <span v-else>{{ $t('dialogues.websiteUsers') }}</span>
                </span>
                <span class="name" v-else>
                  <span v-if="item.source === 'WEB_SOCKET'">
                    <span v-if="item.wechatBot && item.wechatBot.wechatType == 0 && item.wechatBot.bindType == 1">
                      {{ item.sourceName }}
                    </span>
                    <open-data v-else :type="'userName'" :openid="item.sourceName"></open-data>
                  </span>

                  <span v-else>
                    {{ item.sourceName }}
                  </span>
                </span>
                <span class="date">{{
                  new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                }}</span>
              </div>
              <div class="lastMessages">
                <p v-if="item.type == 'RADIO'">
                  <span v-if="item.content.indexOf('以上都不是') !== -1">{{ $t('dialogues.noneOfTheAbove') }}</span>
                  <span v-else>{{ item.content.split("_")[item.content.split("_").length - 1] }}</span>
                </p>
                <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                  {{ JSON.parse(item.content).name }}
                </p>
                <p v-else-if="item.type == 'answer_card_template'">
                  [{{ $t('dialogues.messageCard') }}]
                </p>
                <p v-else-if="item.type == 'FORM_SUBMIT'">
                  [{{ $t('dialogues.form') }}]
                </p>
                <p v-else-if="item.type == 'VALUE_CONFIRM'">
                  [{{ $t('common.confirm') }}]
                </p>
                <p v-else-if="item.type == 'action_assets_card'">
                  [{{ $t('dialogues.assetCard') }}]
                </p>
                <p v-else-if="item.type == 'VIDEO'">
                  [{{ $t('dialogues.video') }}]
                </p>
                <p v-else-if="item.type == 'IMAGE'">
                  [{{ $t('dialogues.image') }}]
                </p>
                <p v-else-if="item.type == 'FEEDBACK'">
                  [{{ $t('dialogues.feedback') }}]
                </p>
                <p v-else-if="item.type == 'answer_intellect_guide'">
                  [{{ $t('dialogues.intellectGuide') }}]
                </p>
                <p v-else-if="item.type == 'answer_voice'">
                  [{{ $t('dialogues.voice') }}]
                </p>
                <p v-else-if="item.type == 'FAST_SELECT'">
                  [{{ $t('dialogues.fastSelect') }}]
                </p>
                <p v-else-if="item.type == 'GOTO'">
                  [{{ $t('dialogues.goto') }}]
                </p>
                <p v-else-if="item.type == 'INTELLIGENT'">
                  {{ JSON.parse(item.content).name }}
                </p>
                <p v-else-if="item.type == 'user_action_to_satisfaction' ||
                  item.type == 'user_action_to_unsatisfactory'
                ">
                  {{ JSON.parse(item.content).input }}
                </p>
                <p v-else-if="item.type == 'im_evaluation'">
                  发送了问卷调查
                </p>
                <p v-else-if="item.type != 'START_SESSION' && item.type != 'END_SESSION'
                ">
                  {{ item.content }}
                </p>
              </div>
            </div>
            <el-link :underline="false" type="primary" v-if="index == userList.length - 1 && moreUsers"
              @click="getUserList(activeBot, item.sourceId, false, item.pageNo)">{{ $t('dialogues.more') }}</el-link>
          </div>
        </div>
      </div>
      <div class="dialog">
        <div class="headerName">{{ $t('dialogues.dialogueRecord') }}</div>
        <div class="dialogContent" id="messageContent" ref="msgContainer">
          <div v-for="(item, index) in userMessages" :key="index">
            <el-link :underline="false" v-if="index == 0 && moreMessages" type="primary" @click="
              clickUserli(
                activeClass,
                userList[activeClass].destId,
                userList[activeClass].sourceId,
                false,
                item.id,
                false,
                userList[activeClass].channel,
                userList[activeClass].ext == null
                  ? null
                  : userList[activeClass].ext.agentId
              )
              ">{{ $t('dialogues.moreMessage') }}</el-link>
            <el-link :underline="false" v-if="index == 0 && !moreMessages" type="primary" disabled>{{
              $t('dialogues.noMore') }}</el-link>
            <div class="message message-kf"
              v-if="item.source == 'WEB_SOCKET' || item.source == 'PWX_SOCKET' || item.source == 'EKB_MALL'
                || item.source == 'DING_SOCKET' || item.source == 'WE_CHART_KF_CLIENT' || item.source == 'DINGTALK_GROUP' || item.source == 'DINGTALK_BOT' || item.source == 'APPLET' || item.source == 'FEISHU_SOCKET' || item.source == 'FEISHU_CHAT' || item.source == 'YUNZHIJIA_SOCKET' || item.source == 'KNOWLEDGE_SOCKET' || item.source == 'ZHIYUAN_SOCKET'">
              <div class="user-detail">
                <div class="media-body">
                  <p v-if="item.type == 'user_action_to_satisfaction' ||
                    item.type == 'user_action_to_unsatisfactory'
                  ">
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <div v-else-if="item.type == 'FORM_SUBMIT'">
                    <FormTemplate :submit="true" :disable="true" :formList="JSON.parse(item.content)" :mainId="mainId">
                    </FormTemplate>
                  </div>
                  <p v-else-if="item.type === 'FEEDBACK'">
                    {{ JSON.parse(item.content).feedback.text }}
                  </p>
                  <div v-else-if="item.type == 'VALUE_CONFIRM'">
                    <TextMessage :submit="true" :disable="true" :text="JSON.parse(item.content)">

                    </TextMessage>
                  </div>
                  <div v-else-if="item.type == 'action_assets_card'">
                    <asset-details :data="msg.content"></asset-details>
                  </div>
                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                    {{ JSON.parse(item.content).name }}
                  </p>
                  <div v-else-if="item.type == 'VIDEO'">
                    <video-player class="video-player vjs-custom-skin" :options="videoOptions(JSON.parse(item.content))"
                      style="width: 350px" />
                  </div>
                  <div v-else-if="item.type == 'IMAGE'" @click="viewImage(JSON.parse(item.content).urls[0])">
                    <el-image style="width: 35%" :src="JSON.parse(item.content).urls[0]"></el-image>
                  </div>

                  <div v-else-if="item.type == 'FAST_SELECT'">
                    <p v-html="JSON.parse(item.content).text"></p>
                  </div>
                  <div v-else-if="item.type == 'INTELLIGENT'">
                    <p v-html="JSON.parse(item.content).name"></p>
                  </div>
                  <div v-else-if="item.type == 'RADIO_V2'">
                    <p v-html="JSON.parse(item.content).optionName"></p>
                  </div>

                  <template v-else>
                    <template v-if="item.type == 'action_transfer4pluto'"></template>
                    <p v-else v-html="item.type == 'RADIO'
                      ? item.content.split('_')[3]
                      : item.content
                      "></p>
                  </template>

                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                  }}</span>
                </div>
                <div class="user-image">
                  <i class="arsenal_icon arsenaljiaose"></i>
                  <!-- <img src="./../../assets/images/Female_user_help.png" /> -->
                  <span>{{ $t('dialogues.user') }}</span>
                </div>
              </div>
            </div>
            <div class="message message-kf" v-if="item.source == 'WEBSITE_SOCKET'">
              <div class="user-detail">
                <div class="media-body">
                  <p v-if="item.type == 'user_action_to_satisfaction' ||
                    item.type == 'user_action_to_unsatisfactory'
                  ">
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <div v-else-if="item.type == 'FORM_SUBMIT'">
                    <FormTemplate :submit="true" :disable="true" :formList="JSON.parse(item.content)" :mainId="mainId">
                    </FormTemplate>
                  </div>
                  <p v-else-if="item.type === 'FEEDBACK'">
                    {{ JSON.parse(item.content).feedback.text }}
                  </p>
                  <div v-else-if="item.type == 'VALUE_CONFIRM'">
                    <TextMessage :submit="true" :disable="true" :text="JSON.parse(item.content)">
                    </TextMessage>
                  </div>

                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                    {{ JSON.parse(item.content).name }}
                  </p>
                  <div v-else-if="item.type === 'office_file' || item.type === 'other_file'" class="pre-message">

                    <file-type :urls="JSON.parse(item.content).urls"></file-type>
                  </div>
                  <div v-else-if="item.type == 'VIDEO'">
                    <video-player class="video-player vjs-custom-skin"
                      :options="videoOptions(JSON.parse(item.content).urls[0])" style="width: 350px" />
                  </div>
                  <div v-else-if="item.type == 'IMAGE'" @click="viewImage(JSON.parse(item.content).urls[0])">
                    <el-image style="width: 35%" :src="JSON.parse(item.content).urls[0]"></el-image>
                  </div>
                  <div v-else-if="item.type == 'INTELLIGENT'">
                    {{ JSON.parse(item.content).name }}
                  </div>
                  <p v-else v-html="item.type == 'RADIO'
                    ? item.content.split('_')[
                    item.content.split('_').length - 1
                    ]
                    : item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)
                    "></p>

                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                  }}</span>
                </div>
                <div class="user-image">
                  <i class="arsenal_icon arsenaljiaose"></i>
                  <!-- <img src="./../../assets/images/Female_user_help.png" /> -->
                  <span>{{ $t('dialogues.user') }}</span>
                </div>
              </div>
            </div>
            <div class="message message-kf" v-if="item.source == 'MINI_PROGRAM_WEB_SOCKET'">
              <div class="user-detail">
                <div class="media-body">
                  <p v-if="item.type == 'user_action_to_satisfaction' ||
                    item.type == 'user_action_to_unsatisfactory'
                  ">
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                    {{ JSON.parse(item.content).name }}
                  </p>

                  <p v-else v-html="item.type == 'RADIO'
                    ? item.content.split('_')[
                    item.content.split('_').length - 1
                    ]
                    : item.content
                    "></p>
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                  }}</span>
                </div>
                <div class="user-image">
                  <i class="arsenal_icon arsenaljiaose"></i>
                  <!-- <img src="./../../assets/images/Female_user_help.png" /> -->
                  <span>{{ $t('dialogues.user') }}</span>
                </div>
              </div>
            </div>

            <!--  用户满意度满意事件-->
            <div class="message" v-if="item.type == 'user_action_to_satisfaction'">
              <template>
                <div class="user-detail" v-if="JSON.parse(item.content) != null">
                  <div class="user-image">
                    <i class="iconfont guoran-askbotdandutubiao2"></i>
                    <!-- <img src="./../../assets/images/chatboticon.png" /> -->
                    <span>BOT</span>
                  </div>
                  <!-- <div class="media-body">

                    <p v-html="JSON.parse(item.content).reply_text"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>-->
                  <div class="media-body-ps" v-if="JSON.parse(item.content).reply_options != null &&
                    JSON.parse(item.content).reply_options.length > 0">
                    <p class="other" v-html="JSON.parse(item.content).reply_text"></p>
                    <p class="ps" v-if="JSON.parse(item.content).reply_options != null &&
                      JSON.parse(item.content).reply_options.length > 0
                    " v-for="(option, index) in JSON.parse(item.content).reply_options" :key="index">
                      {{ option.name }}
                    </p>
                    <span style="padding: 0 15px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname" style="margin-right: 15px">{{ item.sourceId }}</span>
                  </div>
                  <div v-else class="media-body">
                    <p v-html="JSON.parse(item.content).reply_text"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{ item.sourceId }}</span>
                  </div>

                </div>
              </template>
            </div>

            <!--  用户满意度不满意按钮数据   -->
            <div class="message" v-if="item.type == 'user_action_to_unsatisfactory'">
              <template>
                <div class="user-detail" v-if="JSON.parse(item.content) != null">
                  <div class="user-image">
                    <i class="iconfont guoran-askbotdandutubiao2"></i>
                    <!-- <img src="./../../assets/images/chatboticon.png" /> -->
                    <span>BOT</span>
                  </div>
                  <div class="media-body">
                    <p class="other" v-if="JSON.parse(item.content).reply_text != null">
                      {{ JSON.parse(item.content).reply_text }}
                    </p>
                    <p class="ps" v-if="JSON.parse(item.content).reply_options != null &&
                      JSON.parse(item.content).reply_options.length > 0
                    " v-for="(option, index) in JSON.parse(item.content)
                      .options" :key="index">
                      {{ option.name }}
                    </p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span class="botname">{{ userInfor.botName }}</span>
                  </div>
                  <!-- <div class="user-image">
                    <img src="./../../assets/images/Female_user_help.png" />
                    <span>用户</span>
                  </div>-->
                </div>
              </template>
            </div>
            <!-- 人工客服消息 -->
            <template
              v-if="item.source === 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER' || item.source == 'AIMER_SERVER'">
              <div class="message">
                <div class="user-detail">
                  <div class="user-image">
                    <template v-if="item.type !== 'action_alert_iframe'">
                      <template v-if="item.source === 'AIMER_SERVER'">
                        <img :src="item.ext.aimerAgentInfo.avatar"
                          v-if="item.ext && item.ext.aimerAgentInfo && item.ext.aimerAgentInfo.avatar" />
                        <span v-else-if="(item.ext && item.ext.aimerAgentInfo && item.ext.aimerAgentInfo.name)"
                          class="avat-name">
                          {{ item.ext.aimerAgentInfo.name[item.ext.aimerAgentInfo.name.length -
                            2] }}{{ item.ext.aimerAgentInfo.name[item.ext.aimerAgentInfo.name.length - 1] }}
                        </span>
                        <span>{{ item.ext && item.ext.aimerAgentInfo ? item.ext.aimerAgentInfo.name : '客服' }}</span>
                      </template>
                      <template v-else>
                        <img alt="人工客服" src="./../../assets/images/customer_service.png" />
                        <span>{{ $t('dialogues.customerService') }}</span>
                      </template>
                    </template>
                  </div>
                  <div class="media-body">
                    <template v-if="item.type == 'TEXT'">
                      <p v-html="item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></p>
                    </template>
                    <template v-else-if="item.type == 'image'">
                      <p>
                        <!--                        <img  style="width: 100%"
                              :src="item.content"/>-->
                        <el-image @click.native="viewImage(item.content)" style="width: 35%"
                          :src="item.content"></el-image>
                      </p>
                    </template>
                    <template v-else-if="item.type == 'video'">
                      <p>
                        <!--                        <video :src="item.content"></video>-->
                        <video-player class="video-player vjs-custom-skin"
                          :options="videoOptions({ 'url': item.content })" style="width: 350px" />
                      </p>
                    </template>
                    <template v-else-if="item.type === 'office_file' || item.type === 'other_file'">
                      <p>
                        <file-type :urls="JSON.parse(item.content).urls"></file-type>
                      </p>
                    </template>
                    <template v-else-if="item.type == 'im_evaluation'">
                      <imEvaluation :message="item"></imEvaluation>
                    </template>
                    <!-- 客服邀请评价 -->
                    <template
                      v-else-if="item.type === 'action_alert_iframe' && JSON.parse(item.content).displayStyle === 'dialog'">
                      <div class="service-evaluation">
                        <div class="service-evaluation-l">
                          <span class="service-evaluation-icon">
                            <i class="iconfont guoran-pingjia"></i>
                          </span>
                          <span class="service-evaluation-tips">
                            {{ JSON.parse(item.content).description }}
                          </span>
                        </div>
                        <div class="service-evaluation-r">
                          <span>{{ $t('dialogues.toEvaluate') }}</span>
                        </div>
                      </div>
                    </template>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span
                      v-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER' || item.source === 'AIMER_SERVER'"
                      class="botname">{{ item.sourceName }}</span>
                  </div>
                  <div class="revoke" v-if="item.recallStatus && item.recallMessageDisplay">
                    已撤回
                  </div>
                </div>
              </div>
            </template>
            <div class="massage_event"
              v-if="item.source == 'SYSTEM_TO_CLIENT' && item.type == 'event' && JSON.parse(item.content).type != 'recall-message'">
              <span v-if="JSON.parse(item.content).type == 'receiver-offline'">
                <div>{{ $t('dialogues.endSession') }}</div>
              </span>
              <span v-else-if="JSON.parse(item.content).isShow">

                <!-- <receiverMessagePlatform :isShow="JSON.parse(item.content).isShow" :payload="JSON.parse(item.content).payload" :userId="srcUserId"
                  ></receiverMessagePlatform> -->
                <sender-message-platform :isShow="JSON.parse(item.content).isShow"
                  :payload="JSON.parse(item.content).payload"></sender-message-platform>
              </span>
            </div>
            <div class="massage_event" v-if="item.source == 'SYSTEM_TO_CLIENT' &&
              item.type == 'event' &&
              JSON.parse(item.content).type == 'recall-message' &&
              (!JSON.parse(item.content).payload.recallMessageDisplay || JSON.parse(item.content).payload.recallMessageDisplay === 'false')
            ">
              <span style="fontSize:14px;">{{ JSON.parse(item.content).payload.userName }}撤回了一条消息</span>
            </div>
            <!-- 机器人消息 -->
            <template v-if="(item.source == 'GUORAN_BOT' 
            ) &&
              item.type != 'user_action_to_satisfaction' &&
              item.type != 'user_action_to_unsatisfactory' &&
              item.type != 'START_SESSION'
            ">
              <div class="message" v-for="(msg, index) in JSON.parse(item.content)" :key="index">
                <div class="user-detail"
                  v-if="msg.content != null && JSON.stringify(msg.content) != '{}' && (msg.content.isGlobal == null || msg.content.isGlobal == false)">
                  <div class="user-image" v-if="!item.sourceCopy">
                    <i class="iconfont guoran-askbotdandutubiao2"></i>
                    <!-- <img src="./../../assets/images/chatboticon.png" /> -->
                    <span>BOT</span>
                  </div>
                  <div class="user-image" v-else>
                    <template v-if="item.type !== 'action_alert_iframe'">
                      <template v-if="item.source === 'AIMER_SERVER'">
                        <img :src="item.ext.aimerAgentInfo.avatar"
                          v-if="item.ext && item.ext.aimerAgentInfo && item.ext.aimerAgentInfo.avatar" />
                        <span v-else-if="(item.ext && item.ext.aimerAgentInfo && item.ext.aimerAgentInfo.name)"
                          class="avat-name">
                          {{ item.ext.aimerAgentInfo.name[item.ext.aimerAgentInfo.name.length -
                            2] }}{{ item.ext.aimerAgentInfo.name[item.ext.aimerAgentInfo.name.length - 1] }}
                        </span>
                        <span>{{ item.ext && item.ext.aimerAgentInfo ? item.ext.aimerAgentInfo.name : '客服' }}</span>
                      </template>
                      <template v-else>
                        <img alt="人工客服" src="./../../assets/images/customer_service.png" />
                        <span>{{ $t('dialogues.customerService') }}</span>
                      </template>
                    </template>
                  </div>
                  <div class="media-body" v-if="(msg.type == 'answer_text' ||
                    msg.type == 'answer_welcometext' ||
                    msg.type == 'answer_correct') &&
                    msg.content.list != null &&
                    msg.content.list[0] != '__welcome__'
                  ">
                    <p v-html="msg.content.list != null ? msg.content.list[0] : ''
                      "></p>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" style="max-width: 325px" v-else-if="msg.type == 'answer_rich_text'">
                    <p v-html="msg.content.html" class="answer-rich-text-content"></p>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" style="max-width: 350px" v-else-if="msg.type == 'action_confirm_value'">
                    <TextMessage :disable="true" :text="msg.content">
                    </TextMessage>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" style="max-width: 350px"
                    v-else-if="msg.type == 'action_alert_iframe' && msg.content.displayStyle !== 'dialog'">
                    <!-- <ActionAlert :actionAlertIframe="msg.content"></ActionAlert> -->
                    <iframe class="grzh-iframe" sandbox="allow-scripts" :src="msg.content.template"
                      :width="(msg.content.version == 1 && !phoneWidth100) ? msg.content.width : '100%'"
                      :height="msg.content.height" :scrolling="msg.content.scrolling" frameborder="no"
                      border="0"></iframe>
                    <div @click="gotoAlert(msg.content.template)" class="alertUrl">{{ msg.content.template }}</div>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" v-else-if="msg.type === 'unidentified_feedback'">
                    <qw-feedback :msg="msg"></qw-feedback>
                  </div>
                  <div class="media-body" v-else-if="msg.type == 'action_form'">
                    <FormTemplate :disable="true" :formList="msg.content" :mainId="mainId">
                    </FormTemplate>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" style="max-width: 350px" v-else-if="msg.type == 'action_feedback'">
                    <FeedBack :disable="true" :feedBack="msg.content"></FeedBack>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>

                  <div class="media-body" style="max-width: 350px" v-else-if="msg.type == 'answer_intellect_guide'">
                    <AiGuide :aiGuide="msg"></AiGuide>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>

                  <div class="media-body llm_bot_response_text" v-else-if="msg.type === 'llm_bot_response'">
                    <div class="plugin_name" v-if="msgNeedToDisplayPlugin(msg)">{{ getPluginName(msg.content) }}</div>
                    <template v-if="msg.content.toolInvokeInfo && Object.keys(msg.content.toolInvokeInfo).length != 0">
                      <div v-for="(value, key) in msg.content.toolInvokeInfo" class="toolInvokeInfo">
                        <template
                          v-if="value.recordInfo && value.recordInfo.myRunSqlResults && isStringWithHtml(value.recordInfo.myRunSqlResults)">
                          <mark-down-text :chainValues="value.recordInfo.myRunSqlResults" :isHistory="true"
                            class="myRunSqlResults"></mark-down-text>
                        </template>
                        <mark-down-text v-else :chainValues="msg.content.chainValues.output" :msgId="msg.id"
                          :isHistory="true"></mark-down-text>
                      </div>
                    </template>
                    <mark-down-text v-else :chainValues="msg.content.chainValues.output" :msgId="msg.id"
                      :isHistory="true"></mark-down-text>
                    <div class="generate_feedback" v-if="item.recommendState">
                      <div class="dianzan" v-if="item.recommendState === 1">
                        <i class="iconfont guoran-dianzan1"></i>
                      </div>
                      <div class="diancai" v-if="item.recommendState === 2">
                        <i class="iconfont guoran-dianzan1"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body"
                    :class="msg.content && msg.content.items.length != 0 ? 'welcome_card_body' : ''"
                    v-else-if="msg.type === 'welcome_llm_card'">
                    <WelcomeLlmCard :msgContent="msg.content" @llmCardClickFun="llmCardClickFun"></WelcomeLlmCard>
                  </div>
                  <div class="media-body welcome_card_body" v-else-if="msg.type === 'welcome_knowledge_file'">
                    <WelcomeKnowledgeFile :msgContent="msg.content" :mainId="mainId" :userId="srcUserId"
                      :corpId="corpId" :externalPreview="true" @knowledgeClickFun="preview"></WelcomeKnowledgeFile>
                  </div>
                  <div class="media-body  welcome_suggest" v-else-if="msg.type === 'welcome_card_recommend'">
                    <welcomeSuggest :msg="msg"></welcomeSuggest>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body llm_bot_request" v-else-if="msg.type === 'LLM_BOT_REQUEST'">
                    <div class="plugin_name" v-if="msgNeedToDisplayPlugin(msg)">{{ getPluginName(msg.content) }}</div>
                    <div class="files_list" v-if="msg.content.files && msg.content.files.length > 0"
                      :style="{ height: isImage(msg.content.files[0].name) ? 'auto' : '' }">
                      <el-image :src="msg.content.files[0].url" :preview-src-list="[msg.content.files[0].url]"
                        v-if="isImage(msg.content.files[0].name)"></el-image>
                      <div v-else class="file_style">
                        <span class="type_icon">
                          <img width="14px" :src="fileSrc(msg.content.files[0].name)" alt srcset />
                        </span>
                        <span class="file_name">{{ msg.content.files[0].name }}</span>
                      </div>
                    </div>
                    <p>{{ msg.content.text }}</p>
                  </div>

                  <div class="media-body" style="max-width: 350px" v-else-if="msg.type == 'answer_card_template'">
                    <TicketMessage :disable="true" :content="msg.content">
                    </TicketMessage>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" style="max-width: 350px" v-else-if="msg.type == 'action_assets_card'">
                    <asset-details :data="msg.content"></asset-details>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'START_SESSION'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' ||
                      item.source == 'WE_CHART_KF_SERVER'
                    " class="botname">{{ item.sourceId }}</span>
                  </div>
                  <div class="media-body" v-else-if="msg.type == 'action_question' &&
                    msg.content != null &&
                    msg.content.questions != null &&
                    msg.content.questions.length > 0
                  ">
                    <p v-html="msg.content.questions[0]"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" v-else-if="msg.type == 'answer_rich_text_pro'">
                    <div v-html="msg.content.html"></div>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div class="media-body" v-else-if="msg.type == 'answer_image'" @click="viewImage(msg.content.url)">
                    <p>
                      <el-image style="width: 35%" :src="msg.content.url"></el-image>
                    </p>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div v-else-if="msg.type == 'answer_radio'" class="media-body-ps">
                    <p class="other">{{ msg.content.description }}</p>
                    <p class="ps" v-for="(option, index) in msg.content.options" :key="index">
                      {{ option.name }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname"
                      style="margin-top: 5px;margin-right: 15px">{{
                        userInfor.botName
                      }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div v-else-if="msg.type == 'action_satisfaction'" class="media-body-ps">
                    <p class="other">{{ msg.content.satisfactionDes }}</p>
                    <p class="ps" v-for="(option, index) in ['满意', '不满意']" :key="index">
                      {{ option }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname"
                      style="margin-top: 5px;margin-right: 15px">{{
                        userInfor.botName
                      }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>

                  <div v-else-if="msg.type == 'answer_video'" class="media-body">
                    <p>
                      <video-player v-if="msg.type === 'answer_video'" class="video-player vjs-custom-skin"
                        :options="videoOptions(msg.content)" style="width: 350px" />
                    </p>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div v-else-if="msg.type == 'answer_voice'" class="media-body">
                    <p>
                      <AnswerVoice v-if="msg.type === 'answer_voice'" :msg="msg" @stopOtherAudio="stopOtherAudio"
                        ref="audioTagCell">
                      </AnswerVoice>
                    </p>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div v-else-if="msg.type == 'answer_doc_knowledge'" class="media-body">
                    <AnswerDocknowledge v-if="msg.type === 'answer_doc_knowledge'" :msg="msg" :isHistory="true" :isMessageRecord="true" 
                    :isLiBang="mainId == 'de7a4b88bd234b9c8bf5469db6af4982'">
                    </AnswerDocknowledge>
                    <Recocell :item="msg.content" />
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                    <div class="generate_feedback" v-if="item.recommendState">
                      <div class="dianzan" v-if="item.recommendState === 1">
                        <i class="iconfont guoran-dianzan1"></i>
                      </div>
                      <div class="diancai" v-if="item.recommendState === 2">
                        <i class="iconfont guoran-dianzan1"></i>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="msg.type == 'action_transfer'">
                    <!--                                        机器人回复为转人工组件内容 -->
                  </div>
                  <div v-else-if="msg.type == 'action_transfer4pluto'">
                    <!--                                        修复转人工提示消息隐藏 -->
                  </div>
                  <div v-else-if="msg.type == 'action_transfer_2_ewechat'">
                    <!--                                        机器人回复为转企业微信客服组件内容 -->
                  </div>
                  <div v-else-if="msg.type === 'action_send_message_to_bot'">
                    <span>{{ msg.content.description }}</span>
                    <span v-for="(cell, index) in msg.content.options" :key="'_' + index" class="link-cell cursor">{{
                      cell.name }}</span>
                  </div>
                  <div v-else-if="msg.type == 'action_fast_select' && !msg.content.isGlobal" class="media-body-ps">
                    <p class="other">【{{ $t('dialogues.fastSelect') }}】</p>
                    <p class="ps" v-for="(option, index) in msg.content.list" :key="index">
                      {{ option.text }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname"
                      style="margin-top: 5px;margin-right: 15px">{{
                        userInfor.botName
                      }}</span>
                    <span v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname">{{
                        item.sourceId }}</span>
                  </div>
                  <div v-else>
                    <span v-if="JSON.parse(item.content)[0].type === 'action_transfer4pluto'"></span>
                    <p v-else>{{ msg }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="userInfo">
        <div class="headerName">{{ $t('dialogues.userInfo') }}</div>
        <div class="userInfoContent">
          <div class="userBaseInfor">
            <p class="icon"
              v-if="userInfor.user == null || userInfor.user.avatar == null || userInfor.user.avatar === ''">
              <i class="arsenal_icon arsenaluser-circle"></i>
            </p>
            <p class="icon" v-if="userInfor.user != null && userInfor.user.avatar">
              <el-avatar :src="userInfor.user.avatar" :key="userInfor.user.avatar"></el-avatar>
            </p>
            <p class="username">
              <span v-if="userInfor && userInfor.user">
                <template v-if="userInfor.userSource === '网页'">
                  <span v-if="userInfor.user.wechatBot && userInfor.user.wechatBot.channel === 0">
                    <open-data :openid="userInfor.user.userName" :type="'userName'"
                      v-if="userInfor.user.wechatBot.channel === 0 && userInfor.user.wechatBot.bindType === 0"></open-data>
                  </span>
                  <span v-else>{{ userInfor.user.userName && userInfor.user.userName !== '' ? userInfor.user.userName :
                    $t('dialogues.websiteUsers') }}</span>
                </template>
                <template v-else>
                  <open-data :openid="userInfor.user.userName" :type="'userName'"
                    v-if="userInfor.userSource === '企业微信'"></open-data>
                  <span v-else>{{ userInfor.user.userName }}</span>
                </template>
              </span>
              <span></span>
            </p>
            <div class="time">
              <i class="arsenal_icon arsenalclock"></i>
              <span>{{
                userInfor == null
                  ? ""
                  : new Date(userInfor.lastMessageTime).Format(
                    "yyyy-MM-dd hh:mm:ss"
                  )
              }}</span>
            </div>
          </div>
          <!-- 客户访问 -->
          <div class="customerAccess">
            <div class="headerTitle">{{ $t('dialogues.customerVisits') }}</div>
            <div class="itemBot">
              <div class="itemName">{{ $t('dialogues.belongBot') }}：</div>
              <div class="itemNContent">{{ userInfor.botName }}</div>
            </div>
            <div class="itemBot">
              <div class="itemName">{{ $t('dialogues.userSource') }}：</div>
              <div class="itemNContent">{{ userInfor.userSource }}</div>
            </div>
            <div class="itemBot">
              <div class="itemName">{{ $t('dialogues.agentName') }}：</div>
              <div class="itemNContent">{{ userInfor.agentName }}</div>
            </div>
          </div>
          <!-- 客户信息 -->
          <div class="customerAccess">
            <div class="headerTitle">{{ $t('dialogues.customerInfo') }}</div>
            <div class="itemUser">
              <div class="itemName">{{ $t('dialogues.name') }}：</div>
              <div class="itemNContent">
                <span v-if="userInfor && userInfor.user">
                  <template v-if="userInfor.userSource === '网页'">
                    <span v-if="userInfor.user.wechatBot && userInfor.user.wechatBot.channel === 0">
                      <open-data :openid="userInfor.user.userName" :type="'userName'"
                        v-if="userInfor.user.wechatBot.channel === 0 && userInfor.user.wechatBot.bindType === 0"></open-data>
                    </span>
                    <span v-else>{{ userInfor.user.userName && userInfor.user.userName !== '' ? userInfor.user.userName
                      :
                      $t('dialogues.websiteUsers') }}</span>
                  </template>
                  <template v-else>
                    <open-data :openid="userInfor.user.userName" :type="'userName'"
                      v-if="userInfor.userSource === '企业微信'"></open-data>
                    <span v-else>{{ userInfor.user.userName }}</span>
                  </template>
                </span>
                <span></span>
                <!-- {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.userName
                }} -->
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">{{ $t('dialogues.jobNumber') }}：</div>
              <div class="itemNContent">
                {{ userInfor.user == null ? '' : userInfor.user.jobNumber }}
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">{{ $t('dialogues.deptFullName') }}：</div>
              <div class="itemNContent">
                <span
                  v-if="userInfor && userInfor.user && !!userInfor.user.deptFullName && userInfor.user.deptFullName != 'null'">
                  <span v-if="userInfor.userSource === '企业微信'">
                    <span v-for="(item, nIdex) in userInfor.user.deptFullName.split('/').reverse()" :key="nIdex">
                      <span v-show="nIdex !== 0"> / </span>
                      <open-data :openid="item" :type="'departmentName'"></open-data>
                    </span>
                  </span>
                  <span v-else>{{ userInfor.user.deptFullName.split("/").reverse().join(" / ") }}</span>
                </span>
              </div>
            </div>
            <!-- <div class="itemUser">
              <div class="itemName">性 别：</div>
              <div class="itemNContent" v-if="userInfor == null || userInfor.user == null
                ? ''
                : userInfor.user.gender == 2
                ">
                女
              </div>
              <div class="itemNContent" v-if="userInfor == null || userInfor.user == null
                ? ''
                : userInfor.user.gender == 1
                ">
                男
              </div>
            </div> -->
            <!-- <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">手机号：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                  ? ""
                  : userInfor.user.mobile
                }}
              </div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">邮 箱：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                  ? ""
                  : userInfor.user.email
                }}
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">公 司：</div>
              <div class="itemNContent"></div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">职 务：</div>
              <div class="itemNContent"></div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">地 址：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                  ? ""
                  : userInfor.user.address
                }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="empty">
      <div v-show="userList.length == 0 && isEmpty" class="empty-rg">
        <div class="image-outer">
          <img class="empty-image" src="../../assets/images/u1016.png" />
        </div>
        <div class="empty-tips">
          <span class="empty-title">{{ $t('dialogues.emptyTip1') }}</span>
          <span class="empty-warning">{{ $t('dialogues.emptyTip2') }}</span>
          <span class="empty-des">{{ $t('dialogues.emptyTip3') }}</span>
          <span class="empty-des">{{ $t('dialogues.emptyTip4') }}</span>
          <span class="empty-des">
            <el-link type="primary" @click="toBotIndex()">{{ $t('dialogues.emptyTip5') }}</el-link>
          </span>
        </div>
      </div>
    </div>
    <imageView v-if="showImageViewer" :previewList.sync="previewList" :showViewer.sync="showImageViewer"
      :currentImage="currentImage" :isCanDel="false">
    </imageView>
    <el-drawer :withHeader="false" :modal="false" custom-class="acv-search-drawer-dia" :visible.sync="advSearchdrawer"
      :direction="direction" :before-close="handleClose">
      <div>
        <AdvSearch ref="advSearch" @closeAdvSerch="closeAdvSerch" @submitAdvSerch="submitAdvSerch"
          :advSearchData="advSearchData" :advSearchdrawer="advSearchdrawer" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import pageTopHeader from '../../components/page-top-header.vue'
import "video.js/dist/video-js.css";
// import { videoPlayer } from "vue-video-player";
import Emotion from "../../components/Emotion/index";
import FileType from 'askbot-dragon/src/components/FileType'
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
import ActionAlert from 'askbot-dragon/src/components/ActionAlertIframe';
import Recocell from './component/Recocell';
import AssetDetails from "askbot-dragon/src/components/assetDetails.vue";
import OpenData from "@/components/openData";
import AiGuide from "askbot-dragon/src/components/AiGuide";
import AnswerVoice from "askbot-dragon/src/components/AnswerVoice";
import senderMessagePlatform from 'askbot-dragon/src/components/senderMessagePlatform.vue';
import imageView from '../../components/imageView.vue';
import AnswerDocknowledge from "askbot-dragon/src/components/AnswerDocknowledge";
import WelcomeKnowledgeFile from 'askbot-dragon/src/components/welcomeKnowledgeFile';
import WelcomeLlmCard from 'askbot-dragon/src/components/welcomeLlmCard';
import MarkDownText from "./component/markDownText";
import welcomeSuggest from 'askbot-dragon/src/components/welcomeSuggest'
import { imageTypeObj } from "./utils/formatDocList";
import receiverMessagePlatform from 'askbot-dragon/src/components/receiverMessagePlatform.vue'
import AdvSearch from "./component/AdvChatsearch.vue";
import QwFeedback from 'askbot-dragon/src/components/QwFeedback';
import imEvaluation from './component/imEvaluation.vue';
window.addEventListener('message', function (messageEvent) {
  var data = messageEvent.data;
  console.log("from child data: ", data);
  let iframeObj = document.getElementById(data.iframeId);
  if (iframeObj) {
    if (data.width) {
      iframeObj.width = data.width + 'px';
    }
    if (data.height) {
      iframeObj.height = data.height + 'px';
    }
  }
}, false);
export default {
  name: "dialogues",
  components: {
    AiGuide,
    AnswerVoice,
    OpenData,
    Recocell,
    TicketMessage,
    TextMessage,
    FormTemplate,
    FeedBack,
    ActionAlert,
    Emotion,
    FileType,
    AssetDetails,
    pageTopHeader,
    senderMessagePlatform,
    imageView,
    AnswerDocknowledge,
    AdvSearch,
    WelcomeKnowledgeFile,
    WelcomeLlmCard,
    MarkDownText,
    welcomeSuggest,
    receiverMessagePlatform,
    QwFeedback,
    imEvaluation
  },
  data() {
    return {
      advSearchdrawer: false,
      direction: "ttb",
      top: 0,
      advSearchData: {},

      getDataIng: true,
      showPreview: false,
      previewImageUrl: "",
      activePageInfo: {
        headerName: this.$t('dialogues.headerName'),
      },
      searchUserName: "",
      activeClass: 0,
      activeBot: {},
      currentUser: {},
      botList: [],
      moreMessages: false,
      moreUsers: false,
      apiKey: [],
      userList: [
        // {
        //     sourceName: "",
        //     content: "",
        //     destId: "",
        //     source: "",
        //     sourceId: "",
        //     time: "",
        //     type: "",
        // },
      ],
      userMessages: [],
      userInfor: {
        user: [{ userName: "" }],
      },
      page: 1,
      page_count: "",
      accountType: "NORMAL",
      loginSuccessTimes: "",
      searching: false,
      isEmpty: true,
      isNoData: false,
      lastUserList: [],
      isShowEmj: false,
      showImageViewer: false,
      previewList: [],
      currentImage: {},
      mainId: '',

      pluginOptions: [],
      srcUserId: "",
      corpId: ''
    };
  },
  methods: {
    msgNeedToDisplayPlugin(message) {
      if (message.content && message.content.toolInvokeInfo && Object.keys(message.content.toolInvokeInfo).length != 0) {
        return true
      } else {
        return false
      }
    },
    getPluginName(content) {
      let name = ''
      for (const key in content.toolInvokeInfo) {
        this.pluginOptions.forEach(pluginGroup => {
          if (pluginGroup.children) {
            pluginGroup.children.forEach(plugin => {
              if (key == plugin.id) {
                name = plugin.name
              }
            })
          }
        })
      }
      return name
    },
    llmCardClickFun(item) {
      // let count = this.getRandomInt(-3, item.value.length - 1)
      // count = count < 0 ? 0 : count
      // count = count >= item.value.length - 1 ? item.value.length - 1 : count
      // this.$emit("sendMessage", item.value[count]);
    },
    getRandomInt(min, max) {
      return Math.round(Math.random() * (max - min)) + min;
    },
    preview(result) {
      console.log(696, result);
      result.previewSourceType = "dialog"
      this.$emit("preview", result)
    },
    isImage(name) {
      let index = name.lastIndexOf(".")
      let type = name.substr(index + 1);
      return [
        'png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].
        indexOf(type.toLowerCase()) !== -1;
    },
    fileSrc(name) {
      let index = name.lastIndexOf(".")
      let type = name.substr(index + 1);
      return 'https://static.guoranbot.com/cdn-office-website/askbot_doc/' + imageTypeObj[type] + '.png'
    },


    showAdvSearch() {
      this.advSearchdrawer = true;
      // this.$nextTick(() => {
      //   this.$refs.advSearch.getAnnotator();
      // })
    },
    todayData() {
      this.currentDate = "today";
      let endTime = new Date().getTime();
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let todayStartTime = yy + "-" + mm + "-" + dd + " " + "0:0:0";
      let todayStartTimeDate = new Date(
        Date.parse(todayStartTime.replace(/-/g, "/"))
      );
      let startTime = todayStartTimeDate.getTime();
      this.newStartTime = startTime;
      this.newEndTime = endTime;
      this.$refs.myNote.getTrainList({
        botId: this.noteCurrentBot,
        beginTrainTime: this.newStartTime,
        endTrainTime: this.newEndTime,
      });
      this.dateSeleteData = [];
    },
    yesterdayData() {
      this.currentDate = "yesterday";
      var time = new Date().getTime() - 24 * 60 * 60 * 1000;
      var yesday = new Date(time); // 获取的是前一天日期
      yesday =
        yesday.getFullYear() +
        "-" +
        (yesday.getMonth() > 9
          ? yesday.getMonth() + 1
          : yesday.getMonth() + 1) +
        "-" +
        yesday.getDate();

      let yesterdayStartTime = yesday + " " + "0:0:0";
      let yesterdayEndTime = yesday + " " + "23:59:59";
      let yesterdayStartTimeDate = new Date(
        Date.parse(yesterdayStartTime.replace(/-/g, "/"))
      );
      let yesterdayEndTimeDate = new Date(
        Date.parse(yesterdayEndTime.replace(/-/g, "/"))
      );
      let startTime = yesterdayStartTimeDate.getTime();
      let endTime = yesterdayEndTimeDate.getTime();
      this.newStartTime = startTime;
      this.newEndTime = endTime;
      this.$refs.myNote.getTrainList({
        botId: this.noteCurrentBot,
        beginTrainTime: this.newStartTime,
        endTrainTime: this.newEndTime,
      });
      this.dateSeleteData = [];
    },
    lastWeekData() {
      this.currentDate = "lastWeek";
      let endTime = new Date().getTime();
      let lastWeekDate = this.getDay(-6);
      let lastWeekTime = lastWeekDate + " " + "0:0:0";
      let startTimeDate = new Date(
        Date.parse(lastWeekTime.replace(/-/g, "/"))
      );
      let startTime = startTimeDate.getTime();
      this.newStartTime = startTime;
      this.newEndTime = endTime;
      this.$refs.myNote.getTrainList({
        botId: this.noteCurrentBot,
        beginTrainTime: this.newStartTime,
        endTrainTime: this.newEndTime,
      });
      this.dateSeleteData = [];
    },
    lastMonthData() {
      this.currentDate = "lastMonth";
      let endTime = new Date().getTime();
      let lastWeekDate = this.getDay(-29);
      let lastWeekTime = lastWeekDate + " " + "0:0:0";
      let startTimeDate = new Date(
        Date.parse(lastWeekTime.replace(/-/g, "/"))
      );
      let startTime = startTimeDate.getTime();
      this.newStartTime = startTime;
      this.newEndTime = endTime;
      this.$refs.myNote.getTrainList({
        botId: this.noteCurrentBot,
        beginTrainTime: this.newStartTime,
        endTrainTime: this.newEndTime,
      });
      this.dateSeleteData = [];
    },
    allData() {
      this.currentDate = "all";
      this.newStartTime = null;
      this.newEndTime = null;
      this.$refs.myNote.getTrainList({
        botId: this.noteCurrentBot,
        beginTrainTime: null,
        endTrainTime: null,
      });
      this.dateSeleteData = [];
    },
    datePickerChange() {
      if (this.dateSeleteData.value) {
        this.currentDate = "diy";
        let d = this.dateSeleteData.value[0];
        let startTimeDated =
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1) +
          "-" +
          d.getDate() +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes() +
          ":" +
          d.getSeconds();
        let startTimeDate = new Date(
          Date.parse(startTimeDated.replace(/-/g, "/"))
        );
        let startTime = startTimeDate.getTime();
        let end = this.dateSeleteData.value[1];
        let e = new Date(end);
        let endday =
          e.getFullYear() +
          "-" +
          (e.getMonth() + 1) +
          "-" +
          e.getDate();
        let timestamp = new Date().getTime();
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let today = yy + "-" + mm + "-" + dd;
        let todaystamp = Date.parse(today);
        let enddaystamp = Date.parse(endday);
        let endTime = "";
        if (todaystamp === enddaystamp) {
          endTime = timestamp;
        } else {
          let endTimeDated =
            e.getFullYear() +
            "-" +
            (e.getMonth() + 1) +
            "-" +
            e.getDate() +
            " " +
            "23:59:59";
          let endTimeDate = new Date(
            Date.parse(endTimeDated.replace(/-/g, "/"))
          );
          endTime = endTimeDate.getTime();
        }
        if (startTime > timestamp || endTime > timestamp) {
          this.dateSeleteData = [];
          this.$message.error(this.$t('dialogues.timeMsg'));
        } else {
          this.newStartTime = startTime;
          this.newEndTime = endTime;
          this.$refs.myNote.getTrainList({
            botId: this.noteCurrentBot,
            beginTrainTime: this.newStartTime,
            endTrainTime: this.newEndTime,
          });
        }
      } else {
        this.yesterdayData();
      }
    },
    changeGetData() {
      switch (true) {
        case this.currentDate === "today":
          this.todayData();
          break;
        case this.currentDate === "yesterday":
          this.yesterdayData();
          break;
        case this.currentDate === "lastWeek":
          this.lastWeekData();
          break;
        case this.currentDate === "lastMonth":
          this.lastMonthData();
          break;
        case this.currentDate == "all":
          this.allData();
          break;
        case this.currentDate === "diy":
          this.datePickerChange();
          break;
      }
    },
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds =
        today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    formatDateTime(date) {
      var date = new Date(date);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    closeParam(type) {
      this.$delete(this.advSearchData, type);
      if (JSON.stringify(this.advSearchData) == "{}") {
        this.top = 0;
      } else {
        this.top = 40;
      }
      this.$refs.advSearch.clearAdvConditionCell(
        type,
        this.advSearchData
      );
      this.getUserList(this.activeBot, null, true, null, true);
    },
    deleteAllAdv() {
      this.advSearchData = {};
      this.top = 0;
      if (this.$refs.advSearch) {
        this.$refs.advSearch.clearAdvConditionAll();
      }
      this.getUserList(this.activeBot, null, true, null, true);
    },
    closeAdvSerch() {
      this.advSearchdrawer = false;
      // this.$refs.advSearch.clearAdvConditionAll();
    },
    submitAdvSerch(param) {
      this.advSearchData = JSON.parse(param);
      if (Object.keys(this.advSearchData).length == 0) {
        this.top = 0;
      } else {
        this.top = 40;
      }
      this.getUserList(this.activeBot, null, true, null, true);
      this.advSearchdrawer = false;
    },
    // 预览图片
    viewImage(url) {
      this.currentImage = {
        imageUrl: url,
        sortNum: 0
      }
      this.previewList = [url]
      this.showImageViewer = true;
    },
    // 暂停当前播放外的其他音频组件
    stopOtherAudio(refId) {
      console.log(refId, this.$refs.audioTagCell, 'audioTagCell');
      this.$nextTick(() => {
        this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
          console.log(item.activeAudio.paused);
          item.stopAudioPlay(refId);
        })
      })
    },
    gotoAlert(url) {
      window.open(url);
    },
    previewImage(_this) {
      this.showPreview = true;
      this.previewImageUrl = _this.src;
    },
    videoOptions(videoContent) {
      console.log(videoContent);
      return {
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: videoContent.url ? videoContent.url : videoContent,
          },
        ],
        width: "350",
        height: "160",
      };
    },
    changeInput() {
      console.log("e:" + this.searchUserName);
      if (this.searchUserName == "") {
        this.getUserList(this.activeBot, null, true, null, true);
        this.searching = false;
        this.isNoData = false;
      }
    },
    searchUser() {
      this.searching = true;
      this.isEmpty = false;
      this.getUserList(this.activeBot, null, true, null, true);
    },
    scrollBottom() {
      this.$nextTick(() => {
        let container = document.getElementById("messageContent");
        let scrollHeight = container.scrollHeight;
        console.debug("滚动高度", scrollHeight);
        container.scrollTop = scrollHeight;
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        let container = document.getElementById("messageContent");
        container.scrollTop = 0;
      });
    },
    //跳转至机器人页面
    toBotIndex: function () {
      this.$router.push({
        name: "botIndex",
      });
    },
    selectBot(apiKey) {
      this.getDataIng = true;
      this.userList = [];
      this.getUserList(apiKey, null, true, null, true);
    },
    getUserList(apiKey, latestSourceId, showFirstUserInfo, pageNo, clear) {
      const BATCH_USER_LIST_SIZE = 20;
      //const BATCH_USER_LIST_SIZE = 2;
      let url =
        this.requestUrl.messageController.getChatbotMessageList +
        "" +
        apiKey +
        "?" +
        "size=" +
        BATCH_USER_LIST_SIZE;
      if (latestSourceId != null) {
        url = url + "&latestSourceId=" + latestSourceId;
      }
      if (pageNo == null) {
        pageNo = 1;
      }
      url = url + "&pageNo=" + pageNo + "&nameLike=" + this.searchUserName;
      let that = this;
      if (clear == true) {
        this.userList = [];
      }


      // 高级筛选条件
      if (this.advSearchData.lastMsgTime) {
        url = url + "&startTime=" + new Date(this.advSearchData.lastMsgTime[0]).getTime() + "&endTime=" + new Date(this.advSearchData.lastMsgTime[1]).getTime()
      }

      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          if (res.data != null && res.data.length > 0) {
            that.isEmpty = false;
            let userList = res.data;
            console.log(userList);
            // that.userList.push(userList);
            for (
              let i = 0,
              len =
                userList.length > BATCH_USER_LIST_SIZE
                  ? BATCH_USER_LIST_SIZE
                  : userList.length;
              i < len;
              i++
            ) {
              that.userList.push(userList[i]);
            }
            that.lastUserList = that.userList;
            if (res.data.length > BATCH_USER_LIST_SIZE) {
              that.moreUsers = true;
            } else {
              that.moreUsers = false;
            }
            if (showFirstUserInfo != null && showFirstUserInfo == true) {
              let firstUser = that.userList[0];
              that.clickUserli(
                0,
                firstUser.destId,
                firstUser.sourceId,
                true,
                null,
                true,
                firstUser.channel,
                firstUser.ext == null ? null : firstUser.ext.agentId
              );
            }
          } else if (res.data.length == 0 && that.searching == true) {
            that.userList = that.lastUserList;
            console.log("aaa");
            that.isEmpty = false;
            that.isNoData = true;
          } else if (res.data.length == 0 && !that.searching) {
            that.isEmpty = true;
            that.getDataIng = false;
          }
        } else {
          this.$message.error(res.message);
          this.getDataIng = false;
        }
        this.loading = true;
      });
    },
    // 获取机器人列表
    getBotList() {
      this.FetchGet(this.requestUrl.bot.getBotList + '?filterLlmBot=false', {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.code === "0") {
          this.botList = res.data.list;
          this.activeBot = this.botList[0].apiKey;
          this.getDataIng = true;
          this.getUserList(this.activeBot, null, true, null, true);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //点击用户列表
    clickUserli(
      index,
      destId,
      sourceId,
      scroll,
      lastMessageId,
      clearHistory,
      channel,
      agentId
    ) {
      console.log("=============channel:" + channel + ",agentId:" + agentId);
      this.activeClass = index;
      if (clearHistory != null && clearHistory == true) {
        this.userMessages = [];
      }
      console.log(destId, sourceId);
      let url =
        this.requestUrl.messageController.getUserDialog +
        "" +
        this.activeBot +
        "/" +
        sourceId +
        "?size=11&clientType=OPEN" + "&channel=" + channel;
      //let url = this.requestUrl.messageController.getUserDialog+ "" +destId + "/" +sourceId;
      if (lastMessageId != null) {
        url = url + "&lastMessageId=" + lastMessageId;
      }
      // 高级筛选条件
      if (this.advSearchData.lastMsgTime) {
        url = url + "&startTime=" + new Date(this.advSearchData.lastMsgTime[0]).getTime() + "&endTime=" + new Date(this.advSearchData.lastMsgTime[1]).getTime()
      }
      let that = this;
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          let userMessages = res.data;
          //that.userMessages = res.data;
          if (userMessages != null) {
            if (userMessages.length > 10) {

              let slice = userMessages.slice(0, 10);
              for (let i = userMessages.length - 1; i >= 0; i--) {
                console.log(userMessages[i], userMessages[i].type, 'type');
                if (userMessages[i].type == null) {
                  let tampArr = JSON.parse(userMessages[i].content);
                  if (tampArr != null) {
                    tampArr.forEach((cell) => {
                      if (cell.type == "answer_rich_text_pro") {
                        cell.content.html = cell.content.html.replace(
                          /onclick="__rich_text_pro_button_onclick\(this\)"/g,
                          ""
                        );
                      }
                    });
                  }
                  userMessages[i].content = JSON.stringify(tampArr);
                }
                if (userMessages[i].type == "forwardFromAskBot") {
                  setTimeout(() => {
                    this.getImAndBotMes(userMessages[i], userMessages[i].id)
                  }, 100)
                }
                if (userMessages[i].recallStatus && !userMessages[i].recallMessageDisplay) {

                } else {
                  that.userMessages.unshift(userMessages[i]);
                }
              }
              // console.log('wwh' + that.userMessages);
              // that.userMessages = ;
              that.moreMessages = true;
            } else {
              for (let i = userMessages.length - 1; i >= 0; i--) {
                that.userMessages.unshift(userMessages[i]);
                if (userMessages[i].type == "forwardFromAskBot") {
                  setTimeout(() => {
                    this.getImAndBotMes(userMessages[i], userMessages[i].id)
                  }, 100)
                }
              }
              that.moreMessages = false;
            }
            console.log('聊天记录数据------', that.userMessages)
          }
          //切换用户时定位到记录顶部
          if (scroll) {
            //that.scrollBottom();
            setTimeout(function () {
              that.scrollBottom();
            }, "1000");
            //that.scrollToTop();
          }
        } else {
          this.$message.error(res.message);
        }
        this.getDataIng = false;
        this.loading = true;
      });
      this.getUserInformation(this.activeBot, sourceId, channel, agentId);
      /*             this.FetchGet(this.requestUrl.messageController.getUserInformation+ "" +destId + "/" +sourceId,
                ).then(res => {
                    if (res.code === "0") {
                        this.userInfor = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = true;
            }) */
    },
    // 获取人机协同
    getImAndBotMes(item, id) {
      let content = JSON.parse(item.content)
      let url = '/api/im-bot-dialog/one-record/' + content.id
      this.FetchPost(url).then(res => {
        if (res.code == '0') {
          let index = this.userMessages.findIndex(mes => { return mes.id == id })
          if (index != -1) {
            let obj = res.data.dialogResult.processAction[content.param]
            this.userMessages[index].content = JSON.stringify([obj])
            this.userMessages[index].source = 'GUORAN_BOT'
            this.userMessages[index].sourceCopy = 'CUSTOMER_SERVICE_SYSTEM'
          }
        }
      })
    },
    //获取用户详细对话
    getUserDialog() { },
    //获取用户详细对话
    getUserInformation(destId, sourceId, channel, agentId) {
      let url =
        this.requestUrl.messageController.getUserInformation +
        "" +
        destId +
        "/" +
        sourceId;
      if (channel != null) {
        url = url + "?channel=" + channel;
        if (agentId != null) {
          url = url + "&agentId=" + agentId;
        }
      } else {
        if (agentId != null) {
          url = url + "?agentId=" + agentId;
        }
      }
      console.log("=============user info url:" + url);
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          this.userInfor = res.data;
          console.log(this.userInfor);
        } else {
          this.$message.error(res.message);
        }
        this.loading = true;
      });
    },
    // 将匹配结果替换表情图片
    emotion(res) {
      let word = res.replace(/\#|\;/gi, '')
      const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', '不', '可以', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
      let index = list.indexOf(word)
      return `<img height="20px" src="https://guoranim.oss-cn-beijing.aliyuncs.com/emoji/${index + 1}.png" align="middle">`
    },
    //表情
    showEmoji() {
      this.isShowEmj = !this.isShowEmj;
    },
    handleEmotion(i) {
      this.contentText += i
    },
    isStringWithHtml(variable) {
      // 检查是否是字符串
      if (typeof variable !== 'string') {
        return false;
      }

      // 正则表达式检查是否包含HTML标签
      const htmlRegex = /<\/?[a-z][\s\S]*>/i;
      return htmlRegex.test(variable);
    },
  },
  mounted() {
    window.previewImage = this.previewImage;
    this.getBotList();
    this.accountType = localStorage.getItem("accountType");
    this.mainId = localStorage.getItem('_mainId')
    this.srcUserId = localStorage.getItem("_uid")
    //this.getChatbotMessageList();
  },
};
</script>
<style lang="less">
.answer-rich-text-content {
  img {
    max-width: 230px;
    border-radius: 25px;
  }
}
</style>

<style lang="less">
@import "./../../assets/less/dialogues/dialogues.less";

.chat-adv-show {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  background-color: white;
  margin: 0 14px;
  border-radius: 5px 5px 0 0;
  border-bottom: solid 1px #E0E6F7;
  z-index: 20;

  .adv-show-list {
    flex: auto;
    text-align: left;
    margin-left: 10px;
  }

  .adv-show-close {
    flex: none;
    width: 46px;
    margin-top: 3px;

    i {
      font-size: 16px;
      color: #366AFF;
    }
  }
}

.acv-search-drawer-dia {
  // height: 400px !important;
  // width: 700px !important;
  top: 80px !important;
  left: unset !important;
  right: 150px !important;
  width: 535px !important;
  height: 210px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 9px 0px rgba(57, 63, 79, 0.21);
  border-radius: 5px;
  z-index: 2100;
}

.leftInfo {
  display: flex !important;
  justify-content: space-between !important;
}

.alertUrl {
  word-break: break-all;
  margin-top: 8px;
  color: blue;
  cursor: pointer;
}

.vjs-control-bar {
  .vjs-control {
    width: 30px;
    height: 30px;
  }
}

.emotion-container {
  position: absolute;
  bottom: 100px;
  width: 100%;
  background-color: #FFFFFF;
}

/deep/ figure {
  margin-inline-start: 0px
}
</style>
<style lang="less">
.pdf-preview-drawer {
  width: 600px !important;
}

.top-header-right {
  .el-button {
    // width: 91px;
    //max-width: 91px;
    padding: 0 6px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #A1B9FF;
    border-radius: 19px;
    padding: 0 12px;
    font-size: 12px;
    color: #366AFF;
    margin-right: 8px;

    i {
      font-size: 12px;
      // margin-right: 4px;
    }
  }
}

.llm_bot_request {
  .files_list {
    margin-right: 6px;
    margin-bottom: 10px;

    .file_style {
      display: flex;
      align-items: center;
      padding: 4px 6px;
      border-radius: 5px;
      background-color: #eef2ff;
      font-size: 12px;

      .type_icon {
        padding-right: 4px;
        margin-bottom: 0 !important;
        display: flex;
      }

      .file_name {
        margin-bottom: 0 !important;
      }
    }
  }
}

.welcome_card_body {
  padding-right: 15px !important;
}

.welcome_suggest {

  // padding-bottom: 15px;
  .title_change_batch {
    span {
      margin-bottom: 0 !important;
    }
  }
}

.llm_bot_response_text {
  padding-left: 30px !important;
  padding-bottom: 20px !important;
  padding-top: 15px !important;
  max-width: calc(51vw - 130px) !important;

  .toolInvokeInfo {
    width: 100%;

    .myRunSqlResults {
      max-width: calc(51vw - 170px);
      overflow-x: scroll;

      th {
        min-width: 80px;
      }
    }
  }
}

/deep/ .message_list-welcome-m,
/deep/ .message_list_welcome_knowledge_file {
  .mlwm-msg-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .mmt-cell {
      min-width: 290px;
      margin-right: 16px;
      max-width: 390px;
    }
  }
}
</style>